import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import Colors from '../utils/helper/Colors';
import { useTranslation } from 'react-i18next';
import {default as styled_components} from 'styled-components';

const QontoConnectorPre = styled(StepConnector)((theme) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: `${Colors.keepoala.keepoalaGreendefault}`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: `${theme.main ?? Colors.keepoala.keepoalaGreendefault}`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoConnector = styled_components(QontoConnectorPre)`

&.${stepConnectorClasses.active} .${stepConnectorClasses.line} {
    border-color: ${(props) => props.theme.main ?? Colors.keepoala.keepoalaGreendefault};
}
&.${stepConnectorClasses.completed} .${stepConnectorClasses.line} {
    border-color: ${(props) => props.theme.main ?? Colors.keepoala.keepoalaGreendefault};
}
`



const QontoStepIconRoot = styled('div')((props) => ({
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(props.ownerState.active && {
    color: `${Colors.keepoala.keepoalaGreendefault}`,
  }),
  '& .QontoStepIcon-completedIcon': {
    color: `${props.theme.main ?? Colors.keepoala.keepoalaGreendefault}`,
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

// const StepLabelNew = styled(StepLabel)((props) => ({
//   '& .MuiStepLabel-label': {
//     color: `${props.theme.action_text ?? Colors.keepoala.greytext} !important`,
//     fontWeight: 500,
//   },
//   '& .Mui-completed': {
//     color: `${props.theme.main ?? Colors.keepoala.keepoalaGreendefault} !important`,
//     fontWeight: 500,
//   },
//   '& .Mui-disabled': {
//     color: `${props.theme.bg_disabled ?? Colors.keepoala.greydefault} !important`,
//     fontWeight: 500,
//   },
// }));

const QontoStepIconRootStyled = styled_components(QontoStepIconRoot)`
  color: ${(props) => {return props.ownerState.active ? (props.theme.main ?? Colors.keepoala.actionGreen) : (props.theme.bg_disabled ?? Colors.keepoala.lightgrey)}};
  & .QontoStepIcon-completedIcon{
    color: ${(props) => {return (props.theme.main ?? Colors.keepoala.actionGreen)}};
  }
`

const StepLabelNewStyled = styled_components(StepLabel)`
  font-family: ${(props) => { return props.theme.fontFamily ?? 'Inter'}} !important;
  font-weight: ${(props) => { return props.theme.fontFamily !== 'Inter' ? 400 : 500}} !important;
  .MuiStepLabel-label{
    font-family: ${(props) => { return props.theme.fontFamily ?? 'Inter'}} !important;
  }
`

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRootStyled ownerState={{ active }} className={className}>
      {completed ? (
        <Check className='QontoStepIcon-completedIcon' />
      ) : (
        <div className='QontoStepIcon-circle' />
      )}
    </QontoStepIconRootStyled>
  );
}

export default function ProgressStepper(props) {
  const { t } = useTranslation();
  return (
    <Stack sx={{ width: '100%', marginBottom: '20px' }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={props.activeStep}
        connector={<QontoConnector />}
      >
        {props.steps.map((label) => (
          <Step key={label}>
            <StepLabelNewStyled StepIconComponent={QontoStepIcon}>
              {t(label)}
            </StepLabelNewStyled>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
