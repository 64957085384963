import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../styles/Button';
import {
  Box,
  LinearProgress,
  ListItem,
} from '@material-ui/core';
import InterText from '../styles/InterText';
import get_signature from '../utils/helper/get_signature';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@mui/icons-material/Close';

type file_info = {name:string, size:number, type?:string, url?:string}

const get_size_string = (x:number) => {
  return x > 0.5 ? (Math.round(x * 100) / 100).toString() + ' MB' : (
    (Math.round((x * 1024) * 100) / 100) + ' kB'
  )
}

export default function UploadForm({
  answerQuestion,
  text,
  details,
  shop,
  id,
}:{
  answerQuestion: (urls:string[]) => void;
  text?: string;
  details?: string;
  shop: string;
  id: string;
}) {
  const {t} = useTranslation()
  const [progress, setProgress]:[number, any] = useState(0);
  const [file_infos, setFileInfos]:[file_info[], any] = useState([]);
  const [selected_files, setSelectedFiles]:[file_info[], any] = useState([])
  const [message, setMessage]:[string | undefined, any] = useState(undefined);
  const [upload_message, setUploadMessage]:[string | undefined, any] = useState(undefined);
  const [file_objects, setFileObjects]:[any, any] = useState([]);
  const [upload_success, setUploadSuccess]:[boolean, any] = useState(false);
  const [urls, setUrls]:[string[], any] = useState([])

  const hidden_file_input = React.useRef(null);

  const handleClick = (event) => {
    (hidden_file_input.current ?? {click:() => {console.log("NO")}}).click();
  };

  const removeItem = (index) => {
    let current_files:file_info[] = selected_files;
    if (current_files.length > 1) {
      current_files = current_files.splice(index, 1);
      setSelectedFiles(current_files);
      let current_infos:file_info[] = file_infos;
      current_infos = current_infos.splice(index, 1);
      setFileInfos(current_infos);
      let current_file_objects = file_objects;
      current_file_objects = current_file_objects.splice(index, 1);
      setFileObjects(current_file_objects)
    } else {
      setSelectedFiles([]);
      setFileInfos([]);
      setFileObjects([])
    }
  }

  const selectFile = (event) => {
    const current_files:file_info[] = selected_files;
    const internal_files:file_info[] = []

    for (let i = 0; i < event.target.files.length ?? 0; i++) {
      internal_files.push((event.target ?? {files: []}).files[i]);
    }

    if ((current_files.length + internal_files.length) > 3) {
      setMessage(t('Please choose 3 files at max'));
    } else {
      const internal_file_infos:file_info[] = [];

      [...internal_files, ...current_files].map((single_file) => {
        internal_file_infos.push({
          type: single_file.type,
          size: (single_file.size / (1024*1024)),
          name: single_file.name,
          url: single_file.url,
        })
        return true;
      })
      setFileInfos(internal_file_infos);

      const form_element =  (document.querySelector("input[type=file]#btn-upload") as HTMLInputElement);
      if (form_element !== null) {

        const current_file_objects = file_objects;
        if (form_element.files !== null) {
          current_file_objects.push(form_element.files[0])
          setFileObjects(current_file_objects);
        }
      }

      if ([...internal_files, ...current_files]
            .map((x) => (x.size/(1024*1024)))
            .reduce(
              (accumulator:number, currentValue:number) => (accumulator + currentValue),
              0
            )
          > 9.8) {
        setMessage(t('The maximum total file size is 10MB'))
      } else {
        setMessage('');
        setSelectedFiles([...internal_files, ...current_files]);
      }
    }
  }

  const upload = () => {
    setProgress(0.1);
    const data = new FormData();
    const form_element =  (document.querySelector("input[type=file]#btn-upload") as HTMLInputElement);

    if (form_element !== null) {
      const my_files = file_objects
      if (my_files.length > 0) {
        for (const file in my_files) {
          data.append('file' + file.toString(), my_files[file])
        }
        setProgress(5);
        fetch(`https://europe-west1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/typeform_file_upload`, {
            method: "POST",
            headers: {
              Signature: get_signature(shop + "___" + id.toString()),
              shop: shop,
              OrderId: id.toString(),
            },
            body: data
        }).then((res) => {
          if (res.status >= 200 && res.status <= 300) {

            res.json().then((json_value) => {
              console.log(json_value)
              setProgress(100);
              setSelectedFiles([]);
              setUploadMessage(undefined);
              setUploadSuccess(true);
              setUrls(json_value.urls);
            })
          } else {
            res.json().then((json_value) => {
              setUploadMessage(json_value.error);
            })
          }
        })
        .catch((e) => {
          setUploadMessage(e);
        })
      }
    }
  }


  return (
    <div className="mg20">
      {!upload_success ?
        <label htmlFor="btn-upload">
          <input
            id="btn-upload"
            name="btn-upload"
            style={{ display: 'none' }}
            type="file"
            accept='image/*, video/*'
            multiple={true}
            ref={hidden_file_input}
            onChange={selectFile} />
          <Button mb={2}
          variant='contained'
          color='default' onClick={handleClick}>
             {t('Choose Files')}
          </Button>
        </label>
        : null}
      
        
        <br/>

        {message !== undefined && message !== '' ? <Alert color="error">
          {message ?? ''}
        </Alert> : null 
        }
        <br/>

{
  selected_files.length > 0 && <>
  
        <ul className="list-group">
          {file_infos &&
            file_infos.map((file, index) => (
              <ListItem
                divider
                key={('image' + index)}>
                <span>{file.name} ({get_size_string(file.size)})</span> <Button onClick={() => removeItem(index)}><CloseIcon style={{ marginLeft: '3px' }} /></Button>
              </ListItem>
            ))}
        </ul>
        <br/>
  </>
}
        <Button
          mb={2}
          variant='contained'
          color='secondary'
          disabled={
            upload_success ?
            false :
            (selected_files.length < 1 || (message !== undefined && message !== ""))
          }
          onClick={() => {
            upload_success ?
            answerQuestion(urls) :
            upload()
          }}>
           {upload_success ? t('Continue') : t('Upload')}
        </Button>
        {progress > 0 && (
          <Box className="mb25" display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Box minWidth={35}>
              <InterText>{`${progress}%`}</InterText>
            </Box>
          </Box>)
        }
        {upload_message !== undefined && upload_message !== '' ? <Alert color="error">
          {upload_message ?? ''}
        </Alert> : null 
        }
        <br/>
      </div >
  )
}