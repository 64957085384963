import React, { useState, useEffect } from "react";
import { Coloring, LineItemType } from "../types";
import { getThemeByShop } from "../utils/helper/Themes";
import { ThemeProvider } from "styled-components";
import LoadingCircular from "../components/LoadingCircular";

export default function KeepoalaThemeProvider(props: { children: any; shop: string; coloring: Coloring | undefined }) {
  const { coloring, shop } = props;

  const [theme, setTheme]: [Coloring | undefined, any] = useState(undefined);

  useEffect(() => {
    if (
      ((theme === undefined && coloring === undefined) || (theme !== undefined && coloring !== undefined)) &&
      shop !== undefined
    ) {
      const store_theme = getThemeByShop(shop ?? "keepoala", coloring);
      setTheme(store_theme);

      if (store_theme.backgroundImage !== undefined) {
        console.log("HARD SET");
        document.documentElement.style.setProperty("background-color", "#e5e5e5");
        document.documentElement.style.setProperty("background-image", "url(" + store_theme.backgroundImage + ")");
        document.documentElement.style.setProperty("min-height", "100vh");
        document.documentElement.style.setProperty("background-size", "cover");
        console.log(store_theme.fontFamily);
        document.documentElement.style.setProperty("font-family", store_theme.fontFamily + " !important");
        const body_style = document.getElementsByTagName("body")[0].getAttribute("style");
        document
          .getElementsByTagName("body")[0]
          .setAttribute(
            "style",
            (body_style === null ? "" : body_style) + 'font-family: "' + store_theme.fontFamily + '" !important;'
          );
      }
    }
  }, [coloring, shop, theme]);

  return theme !== undefined ? <ThemeProvider theme={theme}>{props.children}</ThemeProvider> : <LoadingCircular />;
}
